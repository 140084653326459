<template>
  <div class="d-flex flex-row">
    <div class="d-flex position-relative" id="file-1">
      <div class="summary-item">
        <div class="summary-icon mr-2">
          <Png v-if="extension === 'png'" class="png-icon" />
          <Pdf v-if="extension === 'pdf'" class="pdf-icon" />
          <Jpg v-if="['jpg', 'jpeg', 'jps'].includes(extension)" class="jpg-icon" />
          <Doc v-if="extension === 'doc'" class="jpg-icon" />
        </div>
        <p class="summary-tag">
          {{file.name}}
        </p>
      </div>
      <transition>
        <div class="remove">
          <Close class="icon" @click="deleteFile" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "BudgetFileBadge",
  props: {
    file: Object,
    budget: Object
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Jpg: () => import('@/assets/icons/jpg.svg'),
    Png: () => import('@/assets/icons/png.svg'),
    Doc: () => import('@/assets/icons/doc.svg'),
  },

  computed: {
    extension() {
      return this.file.name.split('.').pop()
    }
  },

  methods: {
    deleteFile() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
                Você tem certeza que deseja excluir o Arquivo <b>${this.file.name}</b>?
                <p>Não será possível reverter essa ação.</p>
               </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar Exclusão',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          const l = this.$loading.show()
          this.api.deleteBudgetFile(this.file.id).then(res => {
            this.budget.files = this.budget.files.filter(file => file.id !== this.file.id)
            this.$toast.success(`Arquivo ${this.file.name} Excluído.`)
          })
          .catch(error => {
            console.error(error)
            this.$toast.error(`Não foi possível excluir o Arquivo.`)
          })
          .finally(() => {
            l.hide()
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.summary-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  border-radius: 30px;
  background-color: var(--neutral-100);
  margin: 0 8px 8px 0;
  padding: 5px 12px;

  .summary-tag {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-active);
    margin-top: 2px;
  }

  .summary-icon {

    .pdf-icon, .jpg-icon, .png-icon {
      width: 18px !important;
      height: 18px !important;
      margin-left: -8px;
      margin-top: -3px;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.remove {
  margin-top: -2% !important;
  margin-left: -20px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: var(--neutral-200);
  width: 17px;
  height: 17px;
  transition: 0.3s;

  .icon {
    margin-bottom: 12px;
    width: 15px;
    fill: var(--neutral-700);
    margin-left: 1px;
    margin-top: 1px;
  }
}
</style>